


























































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import FormCard from "@/components/FormCard.vue";
import { namespace } from "vuex-class";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import type { IVehicle } from "@/interfaces/IVehicle";
import { Validations } from "vuelidate-property-decorators";
import { required, requiredIf } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import { IInstructor } from "@/interfaces/IInstructor";
import { EDIT } from "@/constants/FormType";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Datepicker from "@/components/Datepicker.vue";
import CompanyMixin from "@/mixins/CompanyMixin";
import { mixins } from "vue-class-component";
import DefaultInstructorSorting from "@/constants/DefaultInstructorSorting";

const InstructorModule = namespace("instructor");
const LicenseClassModule = namespace("license-class");

@Component({
  components: {
    Datepicker,
    FscSimpleCard,
    Validation,
    SaveButton,
    AbortButton,
    FormCard,
  },
})
export default class VehicleForm extends mixins(CompanyMixin) {
  public name = "VehicleForm";

  public id: number | undefined = 0;
  public licensePlate = "";
  public brandAndModel = "";
  public chassisNumber = "";
  public transmission = "";
  public trailer: boolean | undefined = false;
  public instructors: Array<IInstructor> | undefined = [];
  public licenseClasses: Array<string> = [];
  public seasonalClosureStartDate: string | undefined = "";
  public seasonalClosureEndDate: string | undefined = "";
  public generalInspectionDate: string | undefined = "";
  public safetyCheckDate: string | undefined = "";

  @InstructorModule.Action("filter")
  public drivingInstructorsFilterAction: any;

  @InstructorModule.Getter("getDataList")
  public drivingInstructors: any;

  @LicenseClassModule.Action("findAll")
  public licenseClassFindAllAction: any;

  @LicenseClassModule.Getter("getDataList")
  public licenseClass: any;

  @Prop({ type: String, default: () => "create" })
  public type!: string;

  @Prop()
  public data!: any;

  @Prop()
  public visibleSafetyCheckDate!: any;

  @Prop({ type: Boolean, default: () => false })
  public loading!: any;

  public archived = false;
  public activeTab = 0;

  public get transmissionOption(): Array<Record<any, any>> {
    return [
      { text: this.$t("vehicles.automatic"), value: "automatic" },
      { text: this.$t("vehicles.manual"), value: "manual" },
    ];
  }

  public get instructorsOption(): Array<any> {
    return this.drivingInstructors;
  }

  public get licenseClassesOption(): Array<any> {
    return this.licenseClass;
  }

  public mounted(): void {
    this.drivingInstructorsFilterAction({
      resource: "instructor",
      filter: { archived: false, ...DefaultInstructorSorting },
      offset: 0,
      limit: 30,
    });

    this.licenseClassFindAllAction({
      resource: "license-classes",
      id: "",
      params: { archived: this.archived },
    });
  }

  public get typeEdit(): boolean {
    return this.type === EDIT;
  }

  public onSubmit(): void {
    if (this.hasNotPermissionWrite) return;

    this.$v.$touch();

    if (this.$v.$invalid) return;

    const vehicle: IVehicle = {
      brandAndModel: this.brandAndModel,
      chassisNumber: this.chassisNumber,
      transmission: this.transmission,
      licensePlate: this.licensePlate,
      instructors: this.instructors,
      licenseClasses: this.licenseClasses,
      trailer: this.trailer,
    };

    if (this.typeEdit) {
      Object.assign(vehicle, {
        seasonalClosureStartDate: this.seasonalClosureStartDate,
        seasonalClosureEndDate: this.seasonalClosureEndDate,
        generalInspectionDate: this.generalInspectionDate,
        safetyCheckDate: this.safetyCheckDate,
      });
    }

    this.$emit("on-submit", vehicle);
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      brandAndModel: { required },
      licensePlate: { required },
      transmission: {
        required: requiredIf(() => {
          return !this.trailer;
        }),
      },
    };
  }

  @Watch("data", { immediate: true, deep: true })
  public initData(data: IVehicle): void {
    if (data) {
      this.id = data.id;
      this.brandAndModel = data.brandAndModel;
      this.chassisNumber = data.chassisNumber;
      this.licensePlate = data.licensePlate;
      this.instructors = data.instructors;
      this.licenseClasses = data.licenseClasses;
      this.seasonalClosureStartDate = data.seasonalClosureStartDate;
      this.seasonalClosureEndDate = data.seasonalClosureEndDate;
      this.generalInspectionDate = data.generalInspectionDate;
      this.safetyCheckDate = data.safetyCheckDate;
      this.transmission = data.transmission?.toLowerCase();
      this.trailer = data.trailer;
    }
  }

  public onAbort(): void {
    this.$emit("abort");
  }

  public changeTab(index: number) {
    this.activeTab = index;
  }
}
